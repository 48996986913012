import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface ValueDetail {
  value: string;
  count: number;
}

interface ActiveCity {
  city_id: number;        // City ID as a number
  city: string;           // City name as a string
  salons_count: number;   // Total count of salons as a number
  spas_count: number;     // Total count of spas as a number
  business_count: number; // Combined count of businesses (salons + spas) as a number
  salons: ValueDetail[];  // Array of salons with value and count
  spas: ValueDetail[];    // Array of spas with value and count
}

const setCityCookie = (cityId: number, city_name: string) => {
  const frontendData = {
    city_id: cityId,
    city_name: city_name,
    business_count: 0,
  };
  document.cookie = `frontendData=${JSON.stringify(frontendData)}; path=/;`;
};

const SalonsAndSpas: React.FC = () => {
  const [activeCityId, setActiveCityId] = useState<number | null>(null);
  const [locationData, setLocationData] = useState<ActiveCity[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeCity, setActiveCity] = useState<string | null>(null);
  const [salonCount, setSalonCount] = useState<number>(0);
  const [spaCount, setSpaCount] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get('/api/cities/areas');
        setLocationData(response.data);
        setActiveCityId(response.data[0]?.city_id || null); // Set the first city as active by default
        setActiveCity(response.data[0]?.city || null);
        setSalonCount(response.data[0]?.salons_count)
        setSpaCount(response.data[0]?.spas_count)
        setLoading(false);
      } catch (error) {
        console.error("Error fetching city data", error);
        setLoading(false);
      }
    };
    fetchCities();
  }, []);

  const handleTabClick = (cityId: number, city_name: string, salonCount: number, spaCount: number) => {
    setActiveCityId(cityId);
    setActiveCity(city_name);
    setSalonCount(salonCount)
    setSpaCount(spaCount)
  };

  const handleNavigation = (location: string, type: number, cityId:number, city_name: string) => {
    setCityCookie(cityId, city_name);
    const locationParts = location.split(' ');
    if (locationParts.length >= 3) {
      const thirdWord = locationParts[2]; // Get the 3rd word
      window.location.href = `/listing?query=${encodeURIComponent(thirdWord)}&type=${type}`;
    } else {
      console.error("Location does not have enough words");
    }
  };
  
  const activeLocation = locationData.find((location) => location.city_id === activeCityId);

  const tabVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.3 } },
  };

  const contentVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.4 } },
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <h2 className="text-2xl font-semibold mb-4">Best Salons and SPA near you</h2>
      <motion.div
        className="flex overflow-x-auto border-b border-gray-300 scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-200 scrollbar-thumb-rounded hover:scrollbar-thumb-gray-600"

        initial="hidden"
        animate="visible"
        variants={tabVariants}
      >
        {locationData.map((location) => (
          <button
            key={location.city_id}
            onClick={() => handleTabClick(location.city_id, location.city, location.salons_count, location.spas_count)}
            className={`px-4 py-2 whitespace-nowrap ${
              activeCityId === location.city_id ? 'border-b-2 border-black text-black' : 'text-gray-600'
            }`}
          >
            {location.city}
          </button>
        ))}
      </motion.div>
      <div className="my-6">
        <h1 className="font-bold">Explore best Salons near you in {activeCity} ({salonCount})</h1>
        <hr />
      </div>

      <AnimatePresence mode="wait">
        {activeLocation && (
          <>
            <motion.div
              className="grid grid-cols-2 text-xs md:grid-cols-3 lg:grid-cols-6 font-sm gap-6 mt-6"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={contentVariants}
              key={activeCityId}
            >
              {activeLocation.salons.map((salon: ValueDetail, index: number) => (
                <motion.div
                  key={index}
                  className="text-gray-700 cursor-pointer flex gap-6 items-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.05 }}
                  onClick={() => handleNavigation(salon.value, 2, activeLocation.city_id, activeLocation.city)}
                >
                  {salon.value} ({salon.count})
                </motion.div>
              ))}
            </motion.div>
            <div className="my-6">
              <h1 className="font-bold">Explore best Spas near you in {activeCity} ({spaCount})</h1>
              <hr />
            </div>
            <motion.div className="grid grid-cols-2 text-xs md:grid-cols-3 lg:grid-cols-6 font-sm gap-6 mt-6">
              {activeLocation.spas.map((spa: ValueDetail, index: number) => (
                <motion.div
                  key={index}
                  className="text-gray-700 cursor-pointer flex justify-between items-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.05 }}
                  onClick={() => handleNavigation(spa.value, 1, activeLocation.city_id, activeLocation.city)}
                >
                  {spa.value} ({spa.count})
                </motion.div>
              ))}
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SalonsAndSpas;
